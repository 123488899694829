import React, { useEffect, useState, useContext } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { AppContext } from '../../Context';

import CenteredModal from "../../components/modal";
import PaymentMethodModal from "../paymentMethodModal"
import DateRangeModal from '../date-range-modal';

import printInvoice from '../../utils/printInvoice';
import Currency from "../../utils/currency";
import generateSalesChart from '../../utils/generateSalesChart';
import sendApiRequest from '../../utils/sendReq';

import moment from "moment";
import { Button } from 'react-bootstrap';
import jsPDF from 'jspdf';
import axios from 'axios';

import MenuContent from './menu-content';


const BillsContentCarwash = () => {
    const [searchParams] = useSearchParams();
    const { order } = useContext(AppContext);
    const [stateBills, setStateBills] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showDateModal, setDateShowModal] = useState(false);
    const [children, setChildren] = useState('');
    const [title, setTitle] = useState('');
    const [sizeModal, setSizeModal] = useState('');
    const [styleModal, setStyleModal] = useState({});
    const [showPrintModal, setShowPrintModal] = useState(false);
    const [currentBill, setCurrentBill] = useState(null);
    const [currentOrderItemsList, setCurrentOrderItemsList] = useState([]);
    const [orderItemsListParam, setOrderItemsListParam] = useState(null);
    const [billsParam, setBillsParam] = useState(null)
    const router = useNavigate();

    const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });

    const handleDateChange = (startDate, endDate) => {
        setDateRange({ startDate, endDate });
        onClickPrintReport(startDate, endDate);
    };

    const [showModalPayment, setShowModalPayment] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState(null);

    const handleConfirmPaymentModal = (method) => {
        if (method && method.value) {
          setPaymentMethod(method);
          setShowModalPayment(false);
          onClickCompletePayment(orderItemsListParam, billsParam);
          console.log('Selected payment method:', method);
        } else {
          console.error("No valid payment method selected.");
        }
      };

    const onClickPrintReport = async (startDate, endDate) => {
        const startDateFormatted = moment(startDate).format('DDMMYYYY');
        const endDateFormatted = moment(endDate).format('DDMMYYYY');
        const currentDateFormatted = moment().format('DDMMYYYY');
        let datePayload = '';
      
        let billsDataForSelectedRange = [];
        let typeOfReport = 'Daily';
      
        if (startDateFormatted === currentDateFormatted && endDateFormatted === currentDateFormatted) {
          // Get data from localStorage if the date is today
          datePayload = startDate;
          const billsData = localStorage.getItem('orderDataCarwash');
          const bills = JSON.parse(billsData);
          billsDataForSelectedRange = bills[currentDateFormatted]?.data || [];
        } else {
          // Get data from server if the date is not today
          datePayload = `${startDate} - ${endDate}`;
          typeOfReport = 'Custom Range';
          billsDataForSelectedRange = await fetchOrdersByDateRange(startDate, endDate);
        }
      
        if (billsDataForSelectedRange.length === 0) {
          alert('No orders found for the selected date range');
          return;
        }
      
        // Fetch the menu data to map item IDs to full names
        const menuData = await fetch('/menu-carwash.json').then(response => response.json());
      
        // Function to find the item name by ID
        const findItemNameById = (id) => {
          for (const category in menuData) {
            const item = menuData[category].data.find(item => item.id === id);
            if (item) {
              return `${menuData[category].name} - ${item.name}`;
            }
          }
          return 'Unknown Item';
        };
      
        let items = [];
        let totalSales = 0;
        let minusServiceFee = 0;
        billsDataForSelectedRange.forEach((bill) => {
          bill.order_items.forEach((val) => {
            const itemIndex = items.findIndex((itm) => itm.id === val.id);
            const itemName = findItemNameById(val.id);
            if (itemIndex === -1) {
              if (itemName.includes('Free Coffee') || itemName.includes('Voucher 25k')) {
                minusServiceFee += val.sub_total_price * process.env.REACT_APP_PERCENTAGE_FEE_CARWASH;
              }
              items.push({ id: val.id, item_name: itemName, quantity: val.quantity, item_price: val.item_price, sub_total_price: val.sub_total_price });
              totalSales += val.sub_total_price;
            } else {
              if (itemName.includes('Free Coffee') || itemName.includes('Voucher 25k')) {
                items[itemIndex].quantity += val.quantity;
                items[itemIndex].sub_total_price += val.sub_total_price;
                totalSales += val.sub_total_price;
                minusServiceFee += val.sub_total_price * process.env.REACT_APP_PERCENTAGE_FEE_CARWASH;
              } else {
                items[itemIndex].quantity += val.quantity;
                items[itemIndex].sub_total_price += val.sub_total_price;
                totalSales += val.sub_total_price;
              }
            }
          });
        });
      
        console.log(items);
        const totalTransaction = billsDataForSelectedRange.filter((val) => val.status !== 'pending').length;
        const totalServiceFee = totalSales * process.env.REACT_APP_PERCENTAGE_FEE_CARWASH - minusServiceFee;
        console.log(totalServiceFee, minusServiceFee);
        const data = {
          detailSales: items,
          totalSales: totalSales,
          totalServiceFee: totalServiceFee,
          totalTransaction: totalTransaction
        };
      
        const payload = {
          payload: {
            data: {
              detailSales: items.map((val) => {
                return {
                  item_name: val.item_name,
                  quantity: val.quantity,
                  sub_total_price: val.sub_total_price
                };
              }),
              'Total Sales': totalSales,
              'Total Service Fee': totalServiceFee,
              'Total Transaction': totalTransaction
            },
            carwash: {
              name: 'Car Wash Report',
              type: typeOfReport,
              date: moment(startDate).format('DD/MM/YYYY')
            }
          }
        };
        console.log(payload);
      
        if (typeOfReport === 'Custom Range') {
          payload.payload.carwash.date = `${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')}`;
        }
      
        const table = document.getElementById('tableX');
        let tableContent = '<tr><th>No</th><th>Item Name</th><th>Quantity</th><th>Sub Total Price</th></tr><style>table, th, td {border: 1px solid black;}</style>';
        items.forEach((val, index) => {
          tableContent += `<tr><td>${index + 1}</td><td>${val.item_name}</td><td>${val.quantity}</td><td>${Currency(val.sub_total_price)}</td></tr>`;
        });
        table.innerHTML = tableContent;
      
        const chartImage = await generateSalesChart(items);
      
        console.log(payload);
        const jspdf = new jsPDF('p', 'pt', 'a4');
        jspdf.text(20, 20, 'Report-Car Wash');
        jspdf.setFontSize(14);
        jspdf.text(20, 40, moment(startDate).format('DD MMM YYYY') + ' - ' + moment(endDate).format('DD MMM YYYY'));
        jspdf.setFontSize(12);
        jspdf.text(40, 80, `Total Sales : ${Currency(totalSales)}, tanpa service fee`);
        jspdf.text(40, 100, `Service Fee : ${Currency(totalServiceFee)}`);
        jspdf.text(40, 120, `Total Transaksi yang terjadi sebanyak ${totalTransaction} kali.`);
        jspdf.text(40, 140, 'Detail Sales:');
        jspdf.autoTable({ html: '#tableX', startY: 160 });
        jspdf.addImage(chartImage, 'PNG', 40, jspdf.autoTable.previous.finalY + 20, 500, 200);
        jspdf.save(`Report-Car Wash-${moment().format('DD-MMM-YYYY')}.pdf`);
        console.log('Report Generated');
      
        const response = await fetch(process.env.REACT_APP_API_URL_CARWASH + '/order/report', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        }).then((value) => {
          console.log(value);
        });
      
        return;
      };
      

      const fetchOrdersByDateRange = async (startDate, endDate) => {
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL_CARWASH + '/report', {
                startDate: moment(startDate).format('YYYY-MM-DD'),
                endDate: moment(endDate).format('YYYY-MM-DD')
            });
            return response.data;
        } catch (error) {
            console.error(error);
            return [];
        }
    };

    const onClickPrintInvoice = (orderItemsList, bill) => {
        printInvoice.printInvoiceInProgress(bill, '/carwash.png', process.env.REACT_APP_PERCENTAGE_FEE_CARWASH).then(value => {});
    };

    const onClickCompletePayment = async (orderItemsList, bill) => {

        const theMoney = parseFloat(prompt("Uang yang diberikan berapa? :"));
        const dateTime = moment().format("DDMMYYYY");
        const billsData = localStorage.getItem("orderDataCarwash");
        const bills = JSON.parse(billsData);
        const billsDataForToday = bills[dateTime];
        const billsDataForTodayData = billsDataForToday.data;
    
        const billIndex = billsDataForTodayData.findIndex((val) => val.order_num === bill.order_num);
        const serviceFee = process.env.REACT_APP_PERCENTAGE_FEE_CARWASH;
        const theTotal = (billsDataForTodayData[billIndex].total * serviceFee) + billsDataForTodayData[billIndex].total;
        const theKembalian = theMoney - theTotal;

        if (theKembalian < 0) {
            alert("Uang yang diberikan tidak cukup.");
            return;
        }

        billsDataForTodayData[billIndex].status = 'paid';
        billsDataForTodayData[billIndex].dateclosebill = moment().format();
        billsDataForTodayData[billIndex].kembalian = theKembalian;
        billsDataForTodayData[billIndex].money = theMoney;

        try {
            const response = await sendApiRequest(`${process.env.REACT_APP_API_URL_CARWASH}/order`, 'PUT', {
                payload: {
                    order_num: billsDataForTodayData[billIndex].order_num,
                    order_items: billsDataForTodayData[billIndex].order_items,
                        status: billsDataForTodayData[billIndex].status,
                        total: billsDataForTodayData[billIndex].total,
                        date: billsDataForTodayData[billIndex].date,
                        dateclosebill: moment().format(),
                        notes: billsDataForTodayData[billIndex].note,
                        metode_pembayaran: paymentMethod.value
                    }
                });
    
                if (response.message === 'success') {
                    localStorage.setItem("orderDataCarwash", JSON.stringify(bills));
                    setShowModal(false);
                    setChildren('');
                    getBills();
    
                    // Simpan bill dan orderItemsList saat ini untuk digunakan dalam modal cetak
                    setCurrentBill(billsDataForTodayData[billIndex]);
                    setCurrentOrderItemsList(orderItemsList);
                    setShowPrintModal(true); // Tampilkan modal cetak
                } else {
                    alert('Gagal memperbarui pesanan di server. Silakan coba lagi.');
                }
            } catch (error) {
                console.error('Failed to update order on server:', error);
                alert('Gagal memperbarui pesanan di server. Silakan coba lagi.');
            }
        };
    
        const onClickAddOrder = (orderItemsList, bill) => {
            const orderNum = bill.order_num;
            setShowModal(false);
            setChildren(
                <div className='wrapper row'>
                    <MenuContent orderNum={orderNum} orderItemsList={orderItemsList} onUpdate={true} date={bill.date} notes={bill.notes} />
                </div>
            );
            setTitle('Menu');
            setSizeModal('xl');
            setStyleModal({ margin: '20', padding: '0' });
            setShowModal(true);
        };
    
        const showDetailOrder = (bill) => {
            const orderItemsList = bill.order_items.map((item, index) => (
                <div key={index} className='row'>
                    <div className='col'>
                        <h6>{item.item_name}</h6>
                    </div>
                    <div className='col-2'>
                        <h6>{item.quantity}</h6>
                    </div>
                    <div className='col-4'>
                        <h6>@{Currency(item.item_price)}</h6>
                    </div>
                    {item.items && item.items.filter(itm => itm.item_type !== 'default').map((val, index) => (
                        <div key={index} className="col-12 p-0">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item d-flex justify-content-between align-items-center p-0 mx-4" style={{ maxHeight: '25px', marginBlock: '2px' }}>
                                    <span className="order-name" id={item.id}>{val.item_type}</span>
                                    <div className="order-side-price">(+) {Currency(val.item_price)}</div>
                                </li>
                            </ul>
                        </div>
                    ))}
                    <hr />
                </div>
            ));
    
            const serviceFeeRate = process.env.REACT_APP_PERCENTAGE_FEE_CARWASH;
            const totalWithServiceFee = bill.total + (bill.total * serviceFeeRate);
    
            setChildren(
                <div>
                    {bill.status === 'in progress' && (
                        <Button onClick={() => onClickAddOrder(orderItemsList, bill)} style={{ marginLeft: '10px', marginBottom: '10px' }}>
                            + Add Order
                        </Button>
                    )}
                    {orderItemsList}
                    <p>Total + Service Fee : <b>{Currency(totalWithServiceFee)}</b></p>
                    <h4>Notes :</h4>
                    {bill.notes ? bill.notes.map((note, index) => (
                        <div key={index} className='row'>
                            <div className='col-12'>
                                <h6>{note}</h6>
                            </div>
                        </div>
                    )) : <p>No notes</p>}
                    <Button variant="success" onClick={() => onClickPrintInvoice(orderItemsList, bill)}>Print</Button>
                    {bill.status === 'in progress' && (
                        <Button style={{ marginLeft: '10px' }} onClick={() => {
                            setOrderItemsListParam(orderItemsList);
                            setBillsParam(bill);
                            setShowModalPayment(true)
                        }}>
                            Complete Payment
                        </Button>
                    )}
                </div>
            );
    
            setTitle(`Bill No. ${bill.order_num} | ${bill.status}`);
            setShowModal(true);
        };
    
        const getBills = async () => {
            const dateTime = moment().format("DDMMYYYY");
            const billsData = localStorage.getItem("orderDataCarwash");
            const bills = JSON.parse(billsData);
            const billsDataForToday = bills[dateTime];
            setStateBills(billsDataForToday.data);
        }
    
        useEffect(() => {
            getBills();
        }, []);
    
        const handlePrintInvoice = async () => {
            if (currentBill && currentOrderItemsList.length > 0) {
                await printInvoice.printInvoiceInProgress(currentBill, '/carwash.png', process.env.REACT_APP_PERCENTAGE_FEE_CARWASH);
                setShowPrintModal(false); // Tutup modal setelah mencetak struk
            }
        };
    
        const renderPrintInvoiceModal = () => (
            <CenteredModal
                show={showPrintModal}
                onHide={() => setShowPrintModal(false)}
                title="Cetak Struk"
            >
                <p>Apakah Anda ingin mencetak struk untuk transaksi ini?</p>
                <Button variant="primary" onClick={handlePrintInvoice}>Ya, Cetak Struk</Button>
                <Button variant="secondary" onClick={() => setShowPrintModal(false)}>Tidak</Button>
            </CenteredModal>
        );

        
    
        return (
            <div className="container">
                <div>
                    <canvas id="salesChart" width="800" height="400" style={{display: 'none'}}></canvas>
                    <DateRangeModal show={showDateModal} handleClose={() => setDateShowModal(false)} handleDateChange={(start, end) => handleDateChange(start, end)} />
                </div>
                <h1 className="mt-4">Bills</h1>
                <Button className="mb-3" onClick={() => setDateShowModal(true)}>Print Daily Report</Button>
                <div className="row">
                    {stateBills.map((bill, index) => {
                    let bgColor;
                    switch (bill.status) {
                        case 'paid':
                            bgColor = '#2DCCFF';
                            break;
                        case 'in progress':
                            bgColor = '#90EE90';
                            break;
                        case 'cancelled':
                            bgColor = 'yellow';
                            break;
                        default:
                            bgColor = 'gray'; // Default color if no status matches
                    }
                    return (
                        <div className="col-md-4 mb-3" key={index}>
                            <div className="card" style={{ backgroundColor: bgColor }}>
                                <div className="card-body">
                                    <h5 className="card-title">Bill No: {bill.order_num}</h5>
                                    <p className="card-text">Status: <i>{bill.status}</i></p>
                                    <p className="card-text">Total: {Currency(bill.total)}</p>
                                    {bill.notes && bill.notes.length > 0 ? (
                                        <p className="card-text">Note: <b>{bill.notes[0]}</b></p>
                                    ) : (
                                        <p className="card-text" style={{ color: 'gray' }}>Note: nothing</p>
                                    )}
                                    <Button variant="primary" onClick={() => showDetailOrder(bill)}>Show Details</Button>
                                </div>
                            </div>
                        </div>
                    );
                    })}
                </div>
                <table id="tableX" style={{ display: 'none' }}></table>
                <CenteredModal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    title={title}
                    size={sizeModal}
                    style={styleModal}
                >
                    {children}
                </CenteredModal>
                {renderPrintInvoiceModal()}
                <PaymentMethodModal
                    show={showModalPayment}
                    onHide={() => setShowModalPayment(false)}
                    onConfirm={handleConfirmPaymentModal}
                />
            </div>
        );
    }
    
    export default BillsContentCarwash;