import React, { useEffect, useState, useContext } from 'react';
import { AppContext } from '../../Context';
import { Button, Modal, Card } from 'react-bootstrap';
import CenteredModal from "../../components/modal";
import { COLORS } from '../../colors';
import Currency from "../../utils/currency";
import moment from "moment";
import sendApiRequest from '../../utils/sendReq';
import { saveRequest } from '../../utils/offline';
import '../../index.css';

const MenuContent = props => {
    const { menu } = useContext(AppContext);
    const [stateMenu, setStateMenu] = menu;

    const { order } = useContext(AppContext);
    const [stateOrder, setStateOrder] = useState({});
    const [stateOrderItems, setStateOrderItems] = useState([]);
    const [stateOrderNotes, setStateOrderNotes] = useState([]);
    const [dateTime, setDateTime] = useState('');
    const [currOrderNum, setCurrOrderNum] = useState(0);

    const [showModal, setShowModal] = useState(false);
    const [children, setChildren] = useState('');
    const [tempItem, setTempItem] = useState({});
    const [selectedOption, setSelectedOption] = useState({});

    const fetchWithRetry = async (url, method, body, n = 3) => {
        if (!navigator.onLine) {
            console.error('No network connection, saving request to IndexedDB');
            await saveRequest({ url, method, headers: { 'Content-Type': 'application/json' }, body });
            return null; // Indicate that the request was saved instead of sent
        }

        try {
            const response = await sendApiRequest(url, method, body);
            return response;
        } catch (err) {
            if (n === 1) {
                console.error('Failed to fetch data after multiple attempts');
                throw err;
            } else {
                console.warn(`Retrying... (${n - 1} attempts left)`);
                return await fetchWithRetry(url, method, body, n - 1);
            }
        }
    };

    async function getOrder() {
        try {
            const dateTime = moment().format('DDMMYYYY');
            const dataResponse = await fetchWithRetry(process.env.REACT_APP_API_URL_FNB + '/order?date=' + dateTime, 'GET', null);
            const responseOrder = await dataResponse;
            console.log(responseOrder);
            responseOrder.forEach((order) => {
                order.notes = JSON.parse(order.notes);
            });

            if (props.onUpdate) {
                const orderData = JSON.parse(localStorage.getItem('orderData'));
                setCurrOrderNum(props.orderNum);
                const _orderData = orderData[dateTime].data.filter((order) => order.order_num == props.orderNum);
                setStateOrder(_orderData[0]);
                setStateOrderItems(_orderData[0].order_items);
                setStateOrderNotes(_orderData[0].notes);
                return;
            }

            if (responseOrder.length === 0) {
                localStorage.setItem('orderData', JSON.stringify({ [dateTime]: { data: [{ order_num: 1, order_items: [], status: 'pending', total: 0 }] } }));
                setStateOrder({ order_num: 1, order_items: stateOrderItems, total: 0, status: 'pending' });
                setCurrOrderNum(1);
                localStorage.setItem('isFirstTime', true);
                console.log("currOrderNum", currOrderNum)
                return;
            }
            console.log(responseOrder);
            
            const latestOrderNum = responseOrder[responseOrder.length - 1].order_num;
            if (responseOrder[responseOrder.length - 1].status === 'pending') {
                localStorage.setItem('orderData', JSON.stringify({ [dateTime]: { data: responseOrder } }));
                setStateOrder(responseOrder[responseOrder.length - 1]);
                setCurrOrderNum(latestOrderNum);
            } else {
                localStorage.setItem('orderData', JSON.stringify({ [dateTime]: { data: [...responseOrder, { order_num: latestOrderNum + 1, order_items: [], status: 'pending', total: 0 }] } }));
                setStateOrder({ order_num: latestOrderNum + 1, order_items: stateOrderItems, total: 0, status: 'pending' });
                setCurrOrderNum(latestOrderNum + 1);
            }
            
        } catch (err) {
            console.error(err);
        }
    }

    function handleOfflineOrder() {
        const dateTime = moment().format('DDMMYYYY');
        const orderData = JSON.parse(localStorage.getItem('orderData'));

        if (orderData && orderData[dateTime]) {
            const latestOrderNum = orderData[dateTime].data.length - 1;
            if (orderData[dateTime].data[latestOrderNum].status !== 'pending') {
                setStateOrder({ order_num: orderData[dateTime].data[latestOrderNum].order_num + 1, order_items: stateOrderItems, total: 0, status: 'pending' });
                setCurrOrderNum(orderData[dateTime].data[latestOrderNum].order_num + 1);
            } else {
                setStateOrder(orderData[dateTime].data[latestOrderNum]);
                setCurrOrderNum(orderData[dateTime].data[latestOrderNum].order_num);
            }
        } else {
            localStorage.setItem('orderData', JSON.stringify({ [dateTime]: { data: [{ order_num: 1, order_items: stateOrderItems, status: 'pending', total: 0 }] } }));
            setStateOrder({ order_num: 1, order_items: stateOrderItems, total: 0, status: 'pending' });
            setCurrOrderNum(1);
        }
    }

    const handlerOptionsMenu = (item, selectedOption) => {
        console.log(item);
        console.log(selectedOption);
        if (selectedOption.length > 0) {
            selectedOption = JSON.parse(selectedOption);
            console.log(selectedOption.name);
            const allowed = ['id', 'name', 'price']
            const filtered = Object.keys(item)
                .filter(key => allowed.includes(key))
                .reduce((obj, key) => {
                    obj[key] = item[key];
                    return obj;
                }, {});
            console.log(filtered);
            const _item = {
                ...filtered,
                item_price: parseInt(item.price),
                id: item.id,
                items: [
                    {
                        item_type: selectedOption.name,
                        item_price: selectedOption.add_price,
                    }
                ],
                price: parseInt(item.price) + parseInt(selectedOption.add_price),
                isHasOption: true,
            }
            console.log(_item);
            handlerAddMenuContent(_item);
        }
        setChildren('');
        setTempItem({});
        setSelectedOption({});
        setShowModal(false);
        return;
    }

    const handlerAddMenuContent = (item) => {
        console.log(item);
        if (item.item_options?.length > 0) {
            setTempItem(item);
            setChildren(
                <div className="input-group">
                    <select onChange={(e) => { console.log(e); setSelectedOption(e.target.value) }} className="form-select" id="inputGroupSelect04" aria-label="option-select">
                        <option value={''}>Choose...</option>
                        {item.item_options.map((option, index) => {
                            return (
                                <option key={index} value={JSON.stringify(option)}>{option.name}</option>
                            );
                        })}
                    </select>
                </div>
            );
            setShowModal(true);
            return;
        } else {
            var tempStateOrder = stateOrder;
            var _item = {};
            if (item.item_price) {
                item.price = item.item_price;
            }
            if (!item.isHasOption) {
                _item = {
                    id: item.id ? item.id : item.id,
                    item_price: item.price ? item.price : item.item_price,
                    items: [
                        {
                            item_type: 'default',
                            item_price: 0,
                        }
                    ],
                    sub_total_price: item.price ? parseInt(item.price) : parseInt(item.item_price),
                    item_name: item.name,
                    quantity: 1,
                }
                console.log(_item);
            } else {
                _item = {
                    id: item.id,
                    item_price: item.item_price,
                    items: [...item.items],
                    sub_total_price: parseInt(item.price) + parseInt(item.items[0].item_price),
                    item_name: item.name,
                    quantity: 1,
                }
                console.log(_item);
            }
            console.log(tempStateOrder, currOrderNum)
            if (tempStateOrder.order_num == currOrderNum) {
                console.log('order num sama');
                console.log(tempStateOrder.order_items);
                console.log(stateOrderItems);
                if (tempStateOrder.order_items.length === 0) {
                    setStateOrderItems([_item]);
                    tempStateOrder.order_items = [_item];
                    tempStateOrder.total = parseInt(tempStateOrder.total) + parseInt(_item.sub_total_price);
                    setStateOrder({
                        ...tempStateOrder,
                    });
                    return;
                } else if (tempStateOrder.order_items.length > 0) {
                    for (var i = 0; i < tempStateOrder.order_items.length; i++) {
                        if (tempStateOrder.order_items[i].id === item.id) {
                            console.log('item id sama');
                            setStateOrderItems([...stateOrderItems]);
                            stateOrderItems[i].items = [...stateOrderItems[i].items, ..._item.items];
                            stateOrderItems[i].quantity += 1;
                            stateOrderItems[i].sub_total_price = parseInt(stateOrderItems[i].sub_total_price) + parseInt(_item.sub_total_price);
                            tempStateOrder.total = parseInt(tempStateOrder.total) + parseInt(_item.sub_total_price);
                            setStateOrder({
                                ...tempStateOrder,
                            });
                            console.log(stateOrderItems);
                            return;
                        }
                        if (i === tempStateOrder.order_items.length - 1) {
                            setStateOrderItems([...stateOrderItems, _item]);
                            stateOrderItems[i].sub_total_price;
                            tempStateOrder.order_items.push(_item);
                            tempStateOrder.total = parseInt(tempStateOrder.total) + parseInt(_item.sub_total_price);
                            setStateOrder({
                                ...tempStateOrder,
                            });
                            console.log(stateOrderItems);
                            return;
                        }
                    }
                }
            }
        }
        return;
    };

    const deleteOption = (item, index2, index) => {
        console.log(item, index2, index);
        if (item.items.length === 1) {
            setStateOrderItems(stateOrderItems.filter((itm, idx) => itm.id !== item.id));
            stateOrder.order_items = stateOrderItems.filter((itm, idx) => itm.id !== item.id);
            setStateOrder({
                ...stateOrder,
                total: parseInt(stateOrder.total) - parseInt(item.sub_total_price),
            });
            return;
        } else if (item.items.length > 1) {
            stateOrderItems[index].sub_total_price = parseInt(stateOrderItems[index].sub_total_price) - parseInt(item.items[index2].item_price) - parseInt(item.item_price);
            stateOrderItems[index].quantity -= 1;
            const toSubtract = parseInt(item.items[index2].item_price) + parseInt(item.item_price);
            stateOrderItems[index].items = stateOrderItems[index].items.filter((itm, idx) => idx !== index2);

            setStateOrder({
                ...stateOrder,
                total: parseInt(stateOrder.total) - toSubtract,
            });
            console.log(stateOrderItems);
            return;
        } else {
            return;
        }
    };

    const onClickOrder = () => {
        var orderData = localStorage.getItem('orderData');
        orderData = JSON.parse(orderData);
        const dateTime = moment().format('DDMMYYYY');

        if (props.onUpdate) {
            async function updateOrder() {
                const responseOrder = await sendApiRequest(process.env.REACT_APP_API_URL_FNB + '/order', 'PUT', {
                    payload: {
                        order_num: currOrderNum,
                        order_items: stateOrderItems,
                        status: stateOrder.status,
                        total: stateOrder.total,
                        date: props.date,
                        notes: stateOrderNotes,
                    }
                });

                console.log(responseOrder);
            }
            updateOrder().then(() => {
                for (var i = 0; i < orderData[dateTime].data.length; i++) {
                    if (orderData[dateTime].data[i].order_num === currOrderNum) {
                        orderData[dateTime].data[i].status = 'in progress';
                        orderData[dateTime].data[i].order_items = stateOrderItems;
                        orderData[dateTime].data[i].total = stateOrder.total;
                        orderData[dateTime].data[i].date = props.date;
                        orderData[dateTime].data[i].notes = stateOrderNotes;
                        localStorage.setItem('orderData', JSON.stringify(orderData));

                        const lastOrder = orderData[dateTime].data.length - 1;
                        if (orderData[dateTime].data[lastOrder].status !== 'pending') {
                            orderData[dateTime].data.push({ order_num: currOrderNum + 1, order_items: [], status: 'pending', total: 0 });
                        }
                        localStorage.setItem('orderData', JSON.stringify(orderData));
                        setStateOrderItems([]);

                        setStateOrder({ data: [{ order_num: lastOrder + 1, order_items: stateOrderItems, status: 'pending', total: 0 }] });
                        setCurrOrderNum(lastOrder + 1);
                        window.location.reload();
                        return;
                    }
                }
            });
        } else {
            const noteAntiLupa = document.getElementById('note-anti-lupa').value;
            if(noteAntiLupa != ''){
                stateOrderNotes.push(noteAntiLupa);
            }
            async function postOrder() {
                const responseOrder = await sendApiRequest(process.env.REACT_APP_API_URL_FNB + '/order', 'POST', {
                    payload: {
                        order_num: currOrderNum,
                        order_items: stateOrderItems,
                        status: 'in progress',
                        total: stateOrder.total,
                        date: moment().format(),
                        notes: stateOrderNotes,
                    }
                });

                console.log(responseOrder);
            }
            postOrder().then(() => {
                for (var i = 0; i < orderData[dateTime].data.length; i++) {
                    if (orderData[dateTime].data[i].order_num === currOrderNum) {
                        orderData[dateTime].data[i].status = 'in progress';
                        orderData[dateTime].data[i].order_items = stateOrderItems;
                        orderData[dateTime].data[i].total = stateOrder.total;
                        orderData[dateTime].data[i].date = moment().format();
                        orderData[dateTime].data[i].notes = stateOrderNotes;
                        const lastOrder = orderData[dateTime].data.length - 1;
                        if (orderData[dateTime].data[lastOrder].status !== 'pending') {
                            orderData[dateTime].data.push({ order_num: currOrderNum + 1, order_items: [], status: 'pending', total: 0 });
                        }
                        localStorage.setItem('orderData', JSON.stringify(orderData));
                        setStateOrderItems([]);

                        setStateOrder({ data: [{ order_num: lastOrder + 1, order_items: stateOrderItems, status: 'pending', total: 0 }] });
                        setCurrOrderNum(lastOrder + 1);
                        window.location.reload();
                        return;
                    }
                }
            });
        }
    };

    useEffect(() => {
        setStateOrder({})
        getOrder();
    }, []);

    return (
        <div className='col p-0 m-0 wrapper row' style={{ backgroundColor: COLORS.backgroundLight }}>
        <main className="col-md overflow-auto p-0 m-0 px-4" style={{ width: props.width, backgroundColor: COLORS.backgroundWhite }}>
            {stateMenu ? Object.keys(stateMenu).map((category, index) => (
            <div className="row" key={index}>
                <h3 style={{ color: COLORS.textDark }}>{stateMenu[category].name ? stateMenu[category].name : category}</h3>
                {stateMenu[category].items.map((item, index) => (
                <div className="col-4 mb-2 p-1 d-flex" style={{ maxWidth: '200px' }} key={index}>
                    <Card className="flex-fill d-flex flex-column" style={{ backgroundColor: COLORS.backgroundLight, color: COLORS.textDark }}>
                    <Card.Body className="d-flex flex-column p-0">
                        <img src={item.image ? item.image : `/icon/${item.name}.webp`} style={{ maxHeight: '100px', maxWidth: '100px', borderRadius: '8px', marginTop:'8px' }} className="card-img-top mx-auto ada-tiwi-disini" alt="..." />
                        
                    </Card.Body>
                    <Card.Footer>
                        <div className='d-flex justify-content-between align-items-center'>
                        <a className='card-text' href='#' style={{ textDecoration: 'none', color: COLORS.textDark }}>{item.name}</a>
                        <Button onClick={() => handlerAddMenuContent(item)} className="p-0 d-flex align-items-center justify-content-center" style={{ backgroundColor: COLORS.primary, borderColor: COLORS.primary, width: '30px', height: '30px' }}>
                            <img src="/icon/plus.svg" alt="plus" style={{ width: '20px', height: '20px' }} />
                        </Button>
                        </div>
                    </Card.Footer>
                    </Card>
                </div>
                ))}
            </div>
            )) : <div>Loading...</div>}
        </main>
        <nav className="sidebar-menu p-0 col-md-4 fixed-top" style={{ position: 'sticky', top: 0, height: props.height }}>
            <div className="order-side-header" style={{ backgroundColor: COLORS.secondary, color: COLORS.backgroundWhite, borderTopLeftRadius: '6px' }}>
            <h4 className="m-0">Order</h4>
            <h5 className="m-0 mb-1">{`No ${currOrderNum}`}</h5>
            <div className="order-notes">
                {stateOrderNotes ? stateOrderNotes.map((note, index) => (
                <div className="note" key={index}>
                    <span style={{ marginRight: '16px', fontSize: '18px' }}>{note}</span>
                    <Button variant="primary" className="p-0" onClick={() => setStateOrderNotes(stateOrderNotes.filter((_, idx) => idx !== index))}>Delete</Button>
                </div>
                )) : null}
            </div>
            {props.onUpdate ? null : 
            <div className="input-group m-1">
                <input type="text" className="form-control note-order" placeholder="Note Anti Lupa" 
                    id='note-anti-lupa'
                />
            </div>}
            <div className="input-group m-1">
                <input type="text" className="form-control note-order" placeholder="Note Tambahan" aria-label="Note" aria-describedby="button-addon2" />
                <Button variant="primary" type="button" id="button-addon2" onClick={() => {
                const note = document.querySelector('.note-order').value;
                if (note) {
                    setStateOrderNotes([...stateOrderNotes, note]);
                    document.querySelector('.note-order').value = '';
                }
                }}>Add</Button>
            </div>
            </div>
            <div className="order-side-content overflow-auto h-100" style={{ backgroundColor: COLORS.backgroundWhite, color: COLORS.textDark, borderBottomLeftRadius: '6px' }}>
            <ul className="list-group list-group-flush">
                {stateOrderItems ? stateOrderItems.map((item, index) => (
                <li className="list-group-item p-0" key={index} style={{ backgroundColor: COLORS.backgroundLight, color: COLORS.textDark }}>
                    <Card className="w-100 m-0" style={{ boxShadow: '0 1px 2px 1px gray' }}>
                    <Card.Body className="p-2">
                        <div className="row w-100 m-0">
                        <div className="col p-0">
                            <Card.Title className="order-name m-0" id={item.id} itemProp={item.item_name}>{item.item_name}</Card.Title>
                            <Card.Text className="order-side-price m-0">{Currency(item.item_price)}</Card.Text>
                        </div>
                        <div className="col-4 p-0 m-0 d-flex align-items-center">
                            {item.items[0].item_type === 'default' && 
                            <Button variant="primary" className="p-0 m-0 d-flex align-items-center justify-content-center" onClick={() => deleteOption(item, index, index)} style={{ width: '30px', height: '30px', padding: '0' }}>
                                <img src="/icon/minus.svg" alt="minus" style={{ width: '20px', height: '20px' }} />
                            </Button>
                            }
                            <span className="mx-2 col text-center">{item.quantity}</span>
                            <Button variant="primary" className="p-0 m-0 d-flex align-items-center justify-content-center" onClick={() => handlerAddMenuContent(item)} style={{ width: '30px', height: '30px', padding: '0' }}>
                            <img src="/icon/plus.svg" alt="plus" style={{ width: '20px', height: '20px' }} />
                            </Button>
                        </div>
                        </div>
                        {item.items ? item.items.filter((item2) => item2.item_type !== 'default').map((option2, index2) => (
                        <div key={index2} className="col-12 p-0">
                            <ul className="list-group list-group-flush">
                            <li className="list-group-item d-flex justify-content-between align-items-center p-0 mx-4" style={{ maxHeight: '25px', marginBlock: '2px', backgroundColor: COLORS.backgroundLight, color: COLORS.textDark }}>
                                <span className="order-name" id={item.id}>{option2.item_type}</span>
                                <div className="order-side-price">{Currency(option2.item_price)}</div>
                                <Button variant="primary" className="p-0 d-flex align-items-center justify-content-center" onClick={() => deleteOption(item, index2, index)} style={{ maxHeight: '25px', paddingTop: '0px' }}>Delete</Button>
                            </li>
                            </ul>
                        </div>
                        )) : null}
                    </Card.Body>
                    </Card>
                </li>
                )) : <div>Loading...</div>}
            </ul>
            <div className="order-side-footer" style={{ backgroundColor: 'white'}}>
                <h5>Total: {stateOrder.total ? Currency(stateOrder.total) : '0'}</h5>
                <Button className="btn btn-primary" onClick={() => onClickOrder()} style={{ backgroundColor: COLORS.primary, borderColor: COLORS.primary }}>Order</Button>
            </div>
            </div>
        </nav>
        <CenteredModal 
            show={showModal} 
            onHide={() => setShowModal(false)} 
            children={children} 
            onConfirm={() => handlerOptionsMenu(tempItem, selectedOption)} 
        />
    </div>
    );
}

export default MenuContent;
