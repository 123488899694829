import React, { useEffect, useState, useContext } from 'react';
import { AppContext } from '../../Context';

import DateRangeModal from '../date-range-modal';
import CenteredModal from "../../components/modal";
import PaymentMethodModal from '../paymentMethodModal';

import { COLORS } from '../../colors';
import Currency from "../../utils/currency";
import printInvoice from '../../utils/printInvoice';
import sendApiRequest from '../../utils/sendReq';
import generateSalesChart from '../../utils/generateSalesChart';

import moment from "moment";
import { Card, Button,Modal } from 'react-bootstrap';
import axios from 'axios';

import { useNavigate } from 'react-router-dom';
import MenuContent from './menu-content';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const BillsContent = (props) => {
    const { order } = useContext(AppContext);
    const [stateBills, setStateBills] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showDateModal, setDateShowModal] = useState(false);
    const [children, setChildren] = useState('');
    const [title, setTitle] = useState('');
    const [sizeModal, setSizeModal] = useState('');
    const [styleModal, setStyleModal] = useState({});
    const [showPrintModal, setShowPrintModal] = useState(false);
    const [currentBill, setCurrentBill] = useState(null);
    const [currentOrderItemsList, setCurrentOrderItemsList] = useState([]);
    const [currOrderNum, setCurrOrderNum] = useState(0);

    const router = useNavigate();

    const onClickPrintInvoice = (orderItemsList, bill) => {
        var blob = '';
        console.log(orderItemsList);
        console.log(bill);
        const buffer = printInvoice.printInvoiceInProgress(bill, '/kopiagus.png', process.env.REACT_APP_PERCENTAGE_FEE_FNB).then(value => {});
    };

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const [orderItemsListParam, setOrderItemsListParam] = useState(null);
    const [billsParam, setBillsParam] = useState(null)

    const [showModalPayment, setShowModalPayment] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState(null);

    const handleConfirmPaymentModal = (method) => {
        if (method && method.value) {
          setPaymentMethod(method);
          setShowModalPayment(false);
          onClickCompletePayment(orderItemsListParam, billsParam);
          console.log('Selected payment method:', method);
        } else {
          console.error("No valid payment method selected.");
        }
      };

    const onClickCompletePayment = async (orderItemsList, bill) => {
        let theMoney = prompt("Uang yg dikasih berapa? :");
        const dateTime = moment().format("DDMMYYYY");
        const billsData = localStorage.getItem("orderData");
        const bills = JSON.parse(billsData);
        const billsDataForToday = bills[dateTime];
        const billsDataForTodayData = billsDataForToday.data;
    
        const billIndex = billsDataForTodayData.findIndex((val) => val.order_num === bill.order_num);
        let theTotal = (billsDataForTodayData[billIndex].total * 5/100) + billsDataForTodayData[billIndex].total;
        let theKembalian = theMoney - theTotal;
        if(theKembalian<0){
            alert("Uang nya gk cukupp");
            return;
            
        }

        billsDataForTodayData[billIndex].status = 'paid';
        billsDataForTodayData[billIndex].dateclosebill = moment().format();
        billsDataForToday.data = billsDataForTodayData;
        bills[dateTime] = billsDataForToday;
        billsDataForTodayData[billIndex].kembalian = theKembalian;
        billsDataForTodayData[billIndex].money = theMoney;

        try {
            const response = await sendApiRequest(`${process.env.REACT_APP_API_URL_FNB}/order`, 'PUT', {
            payload: {
                order_num: billsDataForTodayData[billIndex].order_num,
                order_items: billsDataForTodayData[billIndex].order_items,
                    status: billsDataForTodayData[billIndex].status,
                    total: billsDataForTodayData[billIndex].total,
                    date: billsDataForTodayData[billIndex].date,
                    dateclosebill: moment().format(),
                    notes: billsDataForTodayData[billIndex].note,
                    metode_pembayaran: paymentMethod.value
                }
            });

            if (response.message === 'success') {
                localStorage.setItem("orderData", JSON.stringify(bills));
                setShowModal(false);
                setChildren('');
                getBills();

                // Simpan bill dan orderItemsList saat ini untuk digunakan dalam modal cetak
                setCurrentBill(billsDataForTodayData[billIndex]);
                setCurrentOrderItemsList(orderItemsList);
                setShowPrintModal(true); // Tampilkan modal cetak
            } else {
                alert('Gagal memperbarui pesanan di server. Silakan coba lagi.');
            }
        } catch (error) {
            console.error('Failed to update order on server:', error);
            alert('Gagal memperbarui pesanan di server. Silakan coba lagi.');
        }
    };

    const onClickAddOrder = (orderItemsList, bill) => {
        const orderNum = bill.order_num;
        setShowModal(false);
        setChildren(
            <div className='wrapper row'>
                <MenuContent orderNum={orderNum} orderItemsList={orderItemsList} onUpdate={true} date={bill.date} notes={bill.notes}/>
            </div>
        );
        setTitle('Menu');
        setSizeModal('xl');
        setStyleModal({ margin: '20', padding: '0' });
        setShowModal(true);
    };

    const showDetailOrder = (bill) => {
        console.log(bill);
        const orderItems = bill.order_items;
        const orderItemsList = orderItems.map((item, index) => {
            console.log(item);
            return (
                <div key={index} className='row'>
                    <div className='col'>
                        <h6>{item.item_name}</h6>
                    </div>
                    <div className='col-2'>
                        <h6>{item.quantity}</h6>
                    </div>
                    <div className='col-4'>
                        <h6>@{Currency(item.item_price)}</h6>
                    </div>
                    {item.items ? item.items.filter((itm) => itm.item_type !== 'default').map((val, index) => {
                        console.log(val);
                        return (
                            <div key={index} className="col-12 p-0">
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item d-flex justify-content-between align-items-center p-0 mx-4" style={{ maxHeight: '25px', marginBlock: '2px' }}>
                                        <span className="order-name" id={item.id}>{val.item_type}</span>
                                        <div className="order-side-price">(+) {Currency(val.item_price)}</div>
                                    </li>
                                </ul>
                            </div>
                        );
                    }) : null}
                    <hr></hr>
                </div>
            );
        });

        async function toSetChildren(orderItemsList, bill){
            return (
                <div>
                    {bill.status === 'in progress' ?
                    <Button onClick={() => onClickAddOrder(orderItemsList, bill)} style={{ marginLeft: '10px', marginBottom: '10px' }}>
                        + Add Order
                    </Button>
                    : null}
                    {orderItemsList}
                    <p>Total + Service Fee : <b>{Currency((bill.total * 5/100) + bill.total)}</b></p>
                    <h4>Notes :</h4>
                    {bill.notes ? bill.notes.map((note, index) => {
                        return (
                            <div key={index} className='row'>
                                <div className='col-12'>
                                    <h6>{note}</h6>
                                </div>
                            </div>
                        );
                    }) : null}
                    <Button style={{ backgroundColor: '#90EE90', borderColor: '#90EE90' }} onClick={() => onClickPrintInvoice(orderItemsList, bill)}>Print</Button>
                    {bill.status === 'in progress' ?
                    <Button style={{ marginLeft: '10px' }} onClick={() => {
                        setOrderItemsListParam(orderItemsList);
                        setBillsParam(bill);
                        setShowModalPayment(true)
                    }}>Complete Payment</Button>
                    : null}
                </div>
            );
        }

        toSetChildren(orderItemsList, bill).then(value => { setChildren(value) });
        setTitle(`Bill No. ${bill.order_num} | ${bill.status}`);
        setShowModal(true);
    };

    const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });

    const handleDateChange = (startDate, endDate) => {
        setDateRange({ startDate, endDate });
        onClickPrintReport(startDate, endDate);
    };

    const onClickPrintReport = async (startDate, endDate) => {
        const startDateFormatted = moment(startDate).format('DDMMYYYY');
        const endDateFormatted = moment(endDate).format('DDMMYYYY');
        const currentDateFormatted = moment().format('DDMMYYYY');
        let datePayload = '';
    
        let billsDataForSelectedRange = [];
        let typeOfReport = 'Daily';
    
        if (startDateFormatted === currentDateFormatted && endDateFormatted === currentDateFormatted) {
            // Fetch today's data from localStorage
            datePayload = startDate;
            const billsData = localStorage.getItem('orderData');
            const bills = JSON.parse(billsData);
            billsDataForSelectedRange = bills[currentDateFormatted]?.data || [];
        } else {
            // Fetch data from the server for a custom range
            datePayload = `${startDate} - ${endDate}`;
            typeOfReport = 'Custom Range';
            billsDataForSelectedRange = await fetchOrdersByDateRange(startDate, endDate);
        }
    
        if (billsDataForSelectedRange.length === 0) {
            alert('No orders found for the selected date range');
            return;
        }
    
        let items = [];
        let totalSales = 0;
        let minusServiceFee = 0;
    
        billsDataForSelectedRange.map((bill) => {
            bill.order_items.map((val) => {
                const itemIndex = items.findIndex((itm) => itm.id === val.id);
                let componentsCount = {};
    
                // If val.components exists and is not null
                if (val.components) {
                    const component = val.components; // Since components is a single string
                    if (component) {
                        if (componentsCount[component]) {
                            componentsCount[component]++;
                        } else {
                            componentsCount[component] = 1;
                        }
                    }
                }
    
                if (itemIndex === -1) {
                    if (val.item_name === 'Free Coffee' || val.item_name === 'Voucher 25k') {
                        minusServiceFee += val.sub_total_price * 5 / 100;
                    }
                    items.push({
                        id: val.id,
                        item_name: val.item_name,
                        quantity: val.quantity,
                        item_price: val.item_price,
                        sub_total_price: val.sub_total_price,
                        components: componentsCount
                    });
                    totalSales += val.sub_total_price;
                } else {
                    if (val.item_name === 'Free Coffee' || val.item_name === 'Voucher 25k') {
                        items[itemIndex].quantity += val.quantity;
                        items[itemIndex].sub_total_price += val.sub_total_price;
                        totalSales += val.sub_total_price;
                        minusServiceFee += val.sub_total_price * 5 / 100;
                    } else {
                        items[itemIndex].quantity += val.quantity;
                        items[itemIndex].sub_total_price += val.sub_total_price;
                        totalSales += val.sub_total_price;
                    }
    
                    // Merge components
                    Object.keys(componentsCount).forEach((component) => {
                        if (items[itemIndex].components[component]) {
                            items[itemIndex].components[component] += componentsCount[component];
                        } else {
                            items[itemIndex].components[component] = componentsCount[component];
                        }
                    });
                }
            });
        });
    
        const totalTransaction = billsDataForSelectedRange.filter((val) => val.status !== 'pending').length;
        const totalServiceFee = totalSales * 5 / 100 - minusServiceFee;
    
        const payloadReq = {
            payload : {
                data: {
                    detailSales: items.map((val) => {
                        return {
                            item_name: val.item_name,
                            quantity: val.quantity,
                            sub_total_price: val.sub_total_price,
                            components: val.components // Include components in the payload
                        };
                    }),
                    'Total Sales': totalSales,
                    'Total Service Fee': totalServiceFee,
                    'Total Transaction': totalTransaction
                },
                fnb: {
                    name: 'Report Sales of Kopi Agus',
                    type: typeOfReport,
                    date: moment(startDate).format('DD/MM/YYYY')
                }
            }
        };
    
        var table = document.getElementById('tableX');

        // Create table content with components
        var tableContent = `
            <tr style="background-color: #f2f2f2; font-weight: bold;">
                <th style="padding: 5px; text-align: left;">No</th>
                <th style="padding: 5px; text-align: left;">Item Name</th>
                <th style="padding: 5px; text-align: right;">Quantity</th>
                <th style="padding: 5px; text-align: right;">Sub Total Price</th>
                <th style="padding: 5px; text-align: left;">Components</th>
            </tr>
            <style>
                table, th, td {
                    border: 1px solid #ddd;
                    padding: 8px;
                }
                table {
                    border-collapse: collapse;
                    width: 100%;
                }
            </style>`;

        items.map((val, index) => {
            // Construct component details, properly handling multiple components
            let componentText = Object.keys(val.components)
                .map(comp => `${comp}: ${val.components[comp]}`)
                .join(', ') || 'N/A';  // Ensure all components are displayed without truncation

            // Append each row to tableContent
            tableContent += `
                <tr>
                    <td style="padding: 5px; text-align: left;">${index + 1}</td>
                    <td style="padding: 5px; text-align: left;">${val.item_name}</td>
                    <td style="padding: 5px; text-align: right;">${val.quantity}</td>
                    <td style="padding: 5px; text-align: right;">${Currency(val.sub_total_price)}</td>
                    <td style="padding: 5px; text-align: left;">${componentText}</td>
                </tr>`;
        });

        // Set the table's inner HTML to the generated table content
        table.innerHTML = tableContent;

        // Generate chart image
        const chartImage = await generateSalesChart(items);

        // Initialize jsPDF and add custom font
        var jspdf = new jsPDF('p', 'pt', 'a4');

        // Add Report title and Date Range with different font size and bold style
        jspdf.setFont('helvetica', 'bold');
        jspdf.setFontSize(18);
        jspdf.text(20, 30, 'Report - AS Kopi Agus');

        // Add Date Range
        jspdf.setFontSize(12);
        jspdf.text(20, 50, `${moment(startDate).format('DD MMM YYYY')} - ${moment(endDate).format('DD MMM YYYY')}`);

        // Add Total Sales, Service Fee, and Transaction Info with some padding
        jspdf.setFontSize(12);
        jspdf.text(40, 90, `Total Sales: ${Currency(totalSales)}, excluding service fee`);
        jspdf.text(40, 110, `Service Fee: ${Currency(totalServiceFee)}`);
        jspdf.text(40, 130, `Total Transactions: ${totalTransaction} transactions`);

        // Add Detail Sales Table to PDF with space between elements
        jspdf.text(40, 160, 'Detail Sales:');

        // Define table styling using `autoTable` plugin
        jspdf.autoTable({
            html: '#tableX',
            startY: 180,
            headStyles: { fillColor: [41, 128, 185], textColor: [255, 255, 255] },  // Blue header with white text
            bodyStyles: { fillColor: [245, 245, 245] },  // Alternating row background colors
            margin: { top: 10 },
            styles: {
                font: 'helvetica',
                fontSize: 10,
                textColor: [40, 40, 40],
                cellPadding: 5
            },
            alternateRowStyles: { fillColor: [255, 255, 255] }
        });

        // Add the chart image to the PDF
        jspdf.addImage(chartImage, 'PNG', 40, jspdf.autoTable.previous.finalY + 20, 500, 200);

        // Save the PDF with a custom filename
        jspdf.save(`Report-AS Kopi Agus-${moment().format('DD-MMM-YYYY')}.pdf`);
        
        console.log(payloadReq);
        const response = await axios.post(process.env.REACT_APP_API_URL_FNB + '/order/report', 
            payloadReq
        ).then((value) => {
            return value
        });
        console.log(response)
        return;
    };

      const fetchOrdersByDateRange = async (startDate, endDate) => {
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL_FNB + '/report', {
                startDate: moment(startDate).format('YYYY-MM-DD'),
                endDate: moment(endDate).format('YYYY-MM-DD')
            });
            console.log("DWWDW", response.data)
            return response.data;
        } catch (error) {
            console.error(error);
            return [];
        }
    };

    const getBills = async () => {
        const dateTime = moment().format("DDMMYYYY");
        const billsData = localStorage.getItem("orderData");
        const bills = JSON.parse(billsData);
        const billsDataForToday = bills[dateTime];
        setStateBills(billsDataForToday.data);
        console.log(billsDataForToday);
    }

    useEffect(() => {
        getBills();
    }, []);

    const handlePrintInvoice = async () => {
        if (currentBill && currentOrderItemsList.length > 0) {
            await printInvoice.printInvoiceInProgress(currentBill, '/kopiagus.png', process.env.REACT_APP_PERCENTAGE_FEE_FNB);
            setShowPrintModal(false); // Tutup modal setelah mencetak struk
        }
    };

    const renderPrintInvoiceModal = () => (
        <CenteredModal
            show={showPrintModal}
            onHide={() => setShowPrintModal(false)}
            title="Cetak Struk"
        >
            <p>Apakah Anda ingin mencetak struk untuk transaksi ini?</p>
            <Button variant="primary" onClick={handlePrintInvoice}>Ya, Cetak Struk</Button>
            <Button variant="secondary" onClick={() => setShowPrintModal(false)}>Tidak</Button>
        </CenteredModal>
    );

    return (
        <div className="container">
            <div>
                {/* <Button onClick={() => setDateShowModal(true)}>Select Date Range</Button> */}
                <canvas id="salesChart" width="800" height="400" style={{display: 'none'}}></canvas>
                <DateRangeModal show={showDateModal} handleClose={() => setDateShowModal(false)} handleDateChange={handleDateChange} />
            </div>
            <h1 className="mt-4">Bills</h1>
            <Button className="mb-3" onClick={() => setDateShowModal(true)}>Print Daily Report</Button>
            <div className="row">
                {stateBills.map((bill, index) => {
                let bgColor;
                switch (bill.status) {
                    case 'paid':
                    bgColor = '#2DCCFF';
                    break;
                    case 'in progress':
                    bgColor = '#90EE90';
                    break;
                    case 'cancelled':
                    bgColor = 'yellow';
                    break;
                    default:
                    bgColor = 'gray'; // Default color if no status matches
                }
                return (
                    <div className="col-md-4 mb-3" key={index}>
                    <div className="card" style={{ backgroundColor: bgColor }}>
                        <div className="card-body">
                        <h5 className="card-title">Bill No: {bill.order_num}</h5>
                        <p className="card-text">Status: <i>{bill.status}</i></p>
                        <p className="card-text">Total: {Currency(bill.total)}</p>
                        {bill.notes && bill.notes.length > 0 ? (
                            <p className="card-text">Note: <b>{bill.notes[0]}</b></p>
                        ) : (
                            <p className="card-text" style={{ color: 'gray' }}>Note: nothing</p>
                        )}
                        <Button variant="primary" onClick={() => showDetailOrder(bill)}>Show Details</Button>
                        </div>
                    </div>
                    </div>
                );
                })}
            </div>
            <table id="tableX" style={{ display: 'none' }}></table>
            <CenteredModal
                show={showModal}
                onHide={() => setShowModal(false)}
                title={title}
                size={sizeModal}
                style={styleModal}
            >
                {children}
            </CenteredModal>
            {renderPrintInvoiceModal()}
            <PaymentMethodModal
                show={showModalPayment}
                onHide={() => setShowModalPayment(false)}
                onConfirm={handleConfirmPaymentModal}
            />
        </div>

    );
}

export default BillsContent;